import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { GenericSelect } from '../components/elements/GenericSelect'
import {
    invalidateClientBudgets,
    useClientBudgets,
    useClientCampaigns,
} from '../hooks/apiHooks'
import PearApi, {
    BudgetList,
    BudgetLineItem,
    NO_LINKED_ASSET,
    Sources,
    Source,
} from '../apis/pearApi'
import FormWindow from '../components/forms/FormWindow'
import StatusDisplay from '../components/StatusDisplay'
import { Condition } from '../components/forms/Fields'
import {
    AdjustmentField,
    BudgetField,
    CalendarField,
    PackageField,
    LabelField,
} from '../components/forms/CompactFields'
import {
    composeValidators,
    notRequired,
    minDollars,
    maxDollars,
    isANumber,
} from '../utility/Validators'
import { dollarFormat, numberFormat, isoDay } from '../utility/Formatting'
import { SectionCloud, Well } from '../components/Layout'
import Icon from '../components/Icon'
import { classNames } from '../utility/Components'
import {
    CheckUIIcon,
    DuplicateUIIcon,
    XUIIcon,
    PlayUIIcon,
    PauseUIIcon,
} from '../components/OtherIcons'
import { LoadingIcon, ErrorIcon } from '../components/StateIcons'
import { Field } from 'react-final-form'
import { LinkButton, ReloadButton } from '../components/elements/Buttons'
import { LargeBadge } from '../components/elements/Badges'
import { useLoginInfo } from '../hooks/LoginInfo'
import { PlatformLinks } from '../apis/ExternalSites'
import AppRoutes from './routes'

const sortBySource = (budgetSourceA, budgetSourceB) => {
    const sourceA = budgetSourceA.source
    const sourceB = budgetSourceB.source

    if (sourceA === sourceB) {
        return 0
    }

    const sourcesInOrder = [
        Sources.GOOGLE,
        Sources.FACEBOOK,
        Sources.MICROSOFT,
        Sources.STACK_ADAPT,
        Sources.LINKEDIN,
        Sources.TIKTOK,
    ]

    const indexA = sourcesInOrder.indexOf(sourceA)
    const indexB = sourcesInOrder.indexOf(sourceB)

    // Sort sources not in the list last
    if (indexA === -1) {
        return 1
    }
    if (indexB === -1) {
        return -1
    }

    return indexA - indexB
}

const sortByLabel = (lineItemA, lineItemB) => {
    const labelA = lineItemA.label
    const labelB = lineItemB.label

    if (labelA === labelB) {
        return 0
    }

    if (labelA === 'Else') {
        return -1
    }

    if (labelB === 'Else') {
        return 1
    }

    return labelA.localeCompare(labelB)
}

const assetTotalWithoutAdjustments = (values, source, assetId) => {
    const assetInfo = values?.[source]?.[assetId] ?? {}
    const lineItems = assetInfo.lineItems || []
    let assetTotal = 0

    for (const lineItem of lineItems) {
        assetTotal += parseFloat(lineItem.budget)
    }
    return assetTotal
}

const lineItemTotal = (lineItem) => {
    const { budget = false, adjustment = false } = lineItem

    let total = 0
    if (!isNaN(parseFloat(budget))) {
        total += parseFloat(budget)
    }
    if (!isNaN(parseFloat(adjustment))) {
        total += parseFloat(adjustment)
    }
    return total
}

const showTotalIfNeeded = (values, source, assetId, index) => {
    const lineItem = values?.[source]?.[assetId]?.lineItems?.[index] ?? {}
    const adjustment = lineItem.adjustment ?? false

    const total = lineItemTotal(lineItem)
    const totalString = total.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })

    return adjustment !== false ? (
        <>
            <div className="flex-initial truncate border border-transparent sm:text-sm">
                {dollarFormat(totalString)}
            </div>
            <div className="flex-initial text-2xl text-gray-500">{'='}</div>
        </>
    ) : null
}

const showLinePkg = (
    assetInfo,
    values,
    source,
    assetId,
    index,
    budgetViewFilter,
) => {
    const budgetViewMode = budgetViewFilter
    const lineItem = values?.[source]?.[assetId]?.lineItems?.[index] ?? {}

    const ratio = assetInfo.budget / assetInfo.package
    const total = lineItemTotal(lineItem)

    if (ratio) {
        const pkg = total / ratio
        lineItem.pkg = Math.round(pkg * 100) / 100
    } else {
        lineItem.pkg = 0
    }

    return (
        <>
            <div className="w-30 truncate border border-transparent sm:text-sm">
                <span className="">
                    {assetInfo.packagePrice <= 0 || !ratio
                        ? '—'
                        : budgetViewMode === 'Ad Spend'
                        ? dollarFormat(lineItem.pkg)
                        : dollarFormat(total)}
                </span>
                <span className="ml-1 align-baseline text-xs font-light">
                    {budgetViewMode === 'Ad Spend' ? 'pkg' : 'spend'}
                </span>
            </div>
        </>
    )
}

const handlePackageChange = (
    value,
    values,
    source,
    assetInfo,
    assetId,
    index,
    fields,
) => {
    const pkgValue = value.target.value
    const ratio = assetInfo.budget / assetInfo.package
    let newLineItem = values?.[source]?.[assetId]?.lineItems?.[index]

    newLineItem.budget = Math.round(pkgValue * ratio * 100) / 100
    newLineItem.pkg = pkgValue
    fields.update(index, newLineItem)
}

const handleDatesChange = (value, values, source, assetId, index, fields) => {
    let newLineItem = values?.[source]?.[assetId]?.lineItems?.[index]
    const [start, end] = value

    newLineItem.startDate = isoDay(start)
    newLineItem.endDate = isoDay(end)

    fields.update(index, newLineItem)
}

interface SourceValues {
    [assetId: string]: {
        lineItems: BudgetLineItem[]
    }
}

interface FormValues {
    [source: string]: SourceValues
}

const labelErrorsInSource = (source: SourceValues) => {
    const allLabelsInSource = Object.values(source ?? {})
        .map((asset) => asset?.lineItems ?? [])
        .reduce(
            (allLineItems, lineItemArr) => allLineItems.concat(lineItemArr),
            [],
        )
        .filter((lineItem) => lineItem.suggested !== true)
        .map((lineItem) => lineItem.label)

    const labelCounts = {}
    const duplicateLabels = []
    allLabelsInSource.forEach((label) => {
        if (!labelCounts[label]) {
            labelCounts[label] = 1
        } else {
            labelCounts[label]++
            duplicateLabels.push(label)
        }
    })

    const sourceErrors = {}
    for (const asset of Object.keys(source)) {
        sourceErrors[asset] = {
            lineItems: (source[asset]?.lineItems ?? [])
                .slice()
                .map(({ label, budget, adjustment, suggested }) => ({
                    suggested: undefined,
                    budget: composeValidators(
                        isANumber,
                        minDollars(0),
                        maxDollars(900000),
                    )(budget),
                    adjustment: notRequired(
                        composeValidators(
                            isANumber,
                            minDollars(budget * -1.0),
                            maxDollars(900000),
                        ),
                    )(adjustment),
                    label: suggested
                        ? undefined
                        : label === undefined
                        ? 'Label must not be empty.'
                        : duplicateLabels.includes(label)
                        ? 'Label must be unique in source.'
                        : undefined,
                })),
        }
    }
    return sourceErrors
}

const budgetComparison = (values, source, assetId, budget) => {
    const totalWithoutAdjustments = assetTotalWithoutAdjustments(
        values,
        source,
        assetId,
    )

    // Rounding here to keep it at the cents level of precision.
    const budgetDelta =
        Math.round(totalWithoutAdjustments * 100 - budget * 100) / 100

    const budgetDifference = Math.abs(budgetDelta)
    const overBudget = budgetDelta > 0
    const underBudget = budgetDelta < 0

    return {
        budgetDifference,
        overBudget,
        underBudget,
        totalWithoutAdjustments,
    }
}

const convertLineItemFormat = (lineItem) => {
    const newLineItem = { ...lineItem, budget: parseFloat(lineItem.budget) }
    if (lineItem.adjustment !== '' && lineItem.adjustment !== undefined) {
        newLineItem.adjustment = parseFloat(lineItem.adjustment)
    } else {
        delete newLineItem.adjustment
    }
    delete newLineItem.pkg
    return newLineItem
}

const LineItem = ({
    name,
    values,
    source,
    id,
    index,
    fields,
    sourceCampaignsLowered,
    campaignCountForElseLabel,
    assetInfo,
    budgetViewFilter,
}) => {
    const lineSource = source ? source.toLowerCase() : ''

    const getDateClassName = (asset, label) => {
        let classDateName = asset.id + '-' + label
        classDateName = classDateName.replace(/ +/g, '-')
        return classDateName
    }

    const onFocusLineItem = (event) => {
        const highlightTerm = event.target.value
        const highlightClass = 'bg-gray-100'
        let highlightTimeout = null

        if (highlightTimeout) {
            clearTimeout(highlightTimeout)
        }

        const divs = document.querySelectorAll(
            'div[data-source=' + lineSource + '][data-type="campaignItem"]',
        )
        divs.forEach((div) => {
            if (
                div.textContent
                    .toLowerCase()
                    .includes(highlightTerm.toLowerCase()) ||
                (highlightTerm.toLowerCase() === 'else' &&
                    div.getAttribute('data-label-count') === '0')
            ) {
                div.classList.add(highlightClass)
                highlightTimeout = setTimeout(() => {
                    div.classList.remove(highlightClass)
                }, 3000)
            } else {
                div.classList.remove(highlightClass)
            }
        })
    }

    return (
        <div>
            <div className="flex items-center gap-3">
                <LabelField
                    className="min-w-1/5"
                    name={`${name}.label`}
                    onFocus={(event) => onFocusLineItem(event)}
                />
                {/* Get the label field value so updates to it affect the campaign count calc. */}
                <Field
                    name={`${name}.label`}
                    component={({ input: { value } }) => {
                        const matchingCampaignCount =
                            value === 'Else'
                                ? campaignCountForElseLabel
                                : sourceCampaignsLowered.reduce(
                                      (count, cn) =>
                                          count +
                                          Number(
                                              value !== '' &&
                                                  value !== undefined &&
                                                  cn.includes(
                                                      value.toLowerCase(),
                                                  ),
                                          ),
                                      0,
                                  )

                        return (
                            <div
                                title="Campaigns covered by each line item"
                                className={classNames(
                                    'campaigns-covered-number flex h-6 w-6 select-none items-center justify-center rounded-full text-sm',
                                    matchingCampaignCount === 0
                                        ? 'bg-orange-100 text-orange-600'
                                        : 'bg-gray-100 text-gray-700',
                                )}
                            >
                                {matchingCampaignCount}
                                <span className="sr-only">
                                    {' '}
                                    {matchingCampaignCount === 1
                                        ? 'campaign'
                                        : 'campaigns'}{' '}
                                    covered
                                </span>
                            </div>
                        )
                    }}
                />
                <div className="packageLine">
                    {showLinePkg(
                        assetInfo,
                        values,
                        source,
                        id,
                        index,
                        budgetViewFilter,
                    )}
                </div>
                <div className="flex min-w-0 grow items-center gap-x-2">
                    {showTotalIfNeeded(values, source, id, index)}
                    {budgetViewFilter === 'Ad Spend' ? (
                        <>
                            <BudgetField
                                className="min-w-0 flex-1"
                                name={`${name}.budget`}
                            />
                            <AdjustmentField
                                className="min-w-0 flex-1"
                                name={`${name}.adjustment`}
                            />
                        </>
                    ) : (
                        <PackageField
                            className="min-w-0 flex-1"
                            name={`${name}.pkg`}
                            onChange={(value) =>
                                handlePackageChange(
                                    value,
                                    values,
                                    source,
                                    assetInfo,
                                    id,
                                    index,
                                    fields,
                                )
                            }
                        />
                    )}
                </div>
                <div className="flex-initial">
                    <Field name={`${name}.adsOnWeekends`} type="checkbox">
                        {({ input }) => {
                            const adsOnWeekends = input.checked
                            return (
                                <button
                                    className={classNames(
                                        'group relative -ml-1 flex h-8 w-8 flex-col items-center justify-center rounded-full bg-gray-50 text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:bg-gray-100 focus:text-gray-700 focus:outline-none',
                                        adsOnWeekends
                                            ? 'border border-gray-100 hover:border-gray-600 focus:border-gray-600'
                                            : 'border-[3px] border-gray-450 hover:border-gray-900 focus:border-gray-900',
                                    )}
                                    type="button"
                                    title={
                                        (adsOnWeekends ? '' : 'No') +
                                        ' Ads on Weekends'
                                    }
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        input.onChange(!adsOnWeekends)
                                    }}
                                >
                                    <span
                                        className="text-[.6rem] font-bold leading-[.6rem]"
                                        aria-hidden="true"
                                    >
                                        SAT
                                    </span>
                                    <span
                                        className="text-[.6rem] font-bold leading-[.6rem]"
                                        aria-hidden="true"
                                    >
                                        SUN
                                    </span>
                                    {!adsOnWeekends ? (
                                        <>
                                            <span className="absolute top-[0.8rem] block h-[3px] w-[30px] -rotate-45 bg-gray-450 group-hover:bg-gray-900 group-focus:bg-gray-900" />
                                            <span className="sr-only">
                                                No ads on weekends
                                            </span>
                                        </>
                                    ) : (
                                        <span className="sr-only">
                                            Ads on weekends
                                        </span>
                                    )}
                                </button>
                            )
                        }}
                    </Field>
                </div>
                <div className="flex-initial">
                    {values?.[source]?.[id]?.lineItems?.[index] ? (
                        <CalendarField
                            className="min-w-0 flex-1"
                            name={getDateClassName(
                                assetInfo,
                                values?.[source]?.[id]?.lineItems?.[index]
                                    .label,
                            )}
                            startDate={
                                values?.[source]?.[id]?.lineItems?.[index]
                                    .startDate ?? null
                            }
                            endDate={
                                values?.[source]?.[id]?.lineItems?.[index]
                                    .endDate ?? null
                            }
                            onChange={(value) =>
                                handleDatesChange(
                                    value,
                                    values,
                                    source,
                                    id,
                                    index,
                                    fields,
                                )
                            }
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="flex-initial">
                    <button
                        className="-ml-1 h-8 w-8 rounded-full bg-orange-200  text-orange-800 hover:bg-orange-800 hover:text-orange-200 focus:bg-orange-800 focus:text-orange-200 focus:outline-none"
                        type="button"
                        title="Remove Line Item"
                        onClick={() => fields.remove(index)}
                    >
                        <Icon type="X" />
                        <span className="sr-only">Remove line item</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

const GrayedTextDisplay = ({ className, value }) => (
    <div className={className}>
        <div className="relative rounded-md">
            <div className="block w-full rounded-md border border-gray-200 bg-gray-100 py-1 px-3 text-sm text-gray-900">
                {value}
            </div>
        </div>
    </div>
)

const SuggestedLineItem = ({ name, values, source, id, index, fields }) => {
    const {
        label,
        budget: budgetString,
        adjustment: adjustmentString,
        adsOnWeekends = true,
    } = values?.[source]?.[id]?.lineItems?.[index] ?? {}
    const budget = parseFloat(budgetString)
    const adjustmentNumber = parseFloat(adjustmentString)
    const adjustment = !isNaN(adjustmentNumber) ? adjustmentNumber : false
    return (
        <div className="flex gap-4">
            <GrayedTextDisplay className="min-w-0" value={label} />
            <div className="flex">
                <LargeBadge className="bg-gray-100 text-gray-800">
                    Suggested
                </LargeBadge>
            </div>
            <div className="flex min-w-0 flex-auto">
                {adjustment !== false && (
                    <>
                        <GrayedTextDisplay
                            className="min-w-0 flex-1"
                            value={dollarFormat(budget + adjustment)}
                        />
                        {/*<div className="mt-1 pt-2">*/}
                        <div className="flex-initial px-4 pt-2 text-2xl text-gray-500">
                            {'='}
                        </div>
                    </>
                )}
                <GrayedTextDisplay
                    className="min-w-0 flex-1"
                    value={dollarFormat(budget)}
                />
                {adjustment !== false && (
                    <>
                        <div className="flex-initial px-4 pt-2 text-2xl text-gray-500">
                            +
                        </div>
                        <GrayedTextDisplay
                            className="min-w-0 flex-1"
                            value={dollarFormat(adjustment)}
                        />
                    </>
                )}
            </div>
            <div className="flex-initial">
                <button
                    className="h-[30px] w-[30px] rounded-full bg-green-200 text-green-800 hover:bg-green-800 hover:text-green-200 focus:bg-green-800 focus:text-green-200 focus:outline-none"
                    type="button"
                    onClick={() => {
                        const newLineItem = {
                            label,
                            budget,
                            adjustment,
                            adsOnWeekends,
                        }
                        if (adjustment === false) {
                            delete newLineItem.adjustment
                        }
                        fields.update(index, newLineItem)
                    }}
                >
                    <Icon type="Check" />
                </button>
            </div>
        </div>
    )
}

const Asset = ({
    assetInfo,
    values,
    source,
    clientCampaigns,
    campaignCountForElseLabel,
    budgetViewFilter,
}) => {
    const {
        id,
        name,
        budget,
        // future reserved word so the variable needs a different name
        package: packagePrice,
        note,
        promo,
        digAdType,
        campaignType,
        lineItems,
    } = assetInfo
    const isUnlinked = id === 'no-asset'

    const {
        budgetDifference,
        overBudget,
        underBudget,
        totalWithoutAdjustments,
    } = budgetComparison(values, source, id, budget)
    const budgetNotMet = overBudget || underBudget

    const hasExtraInfo = promo || campaignType || digAdType

    const sourceCampaignsLowered = clientCampaigns.map((c) =>
        c.name.toLowerCase(),
    )

    return (
        <div className={'mb-4 rounded-md bg-white shadow-md last:mb-0'}>
            <div
                className={classNames(
                    'flex gap-4 rounded-t-md py-2 px-3',
                    isUnlinked ? 'bg-lime-100' : 'bg-gray-50',
                )}
            >
                <div className="min-w-0 flex-auto">
                    <div className="flex items-baseline gap-3">
                        <h3
                            className={classNames(
                                'block min-w-0 shrink truncate text-lg font-semibold',
                                isUnlinked ? 'text-lime-900' : 'text-gray-900',
                            )}
                        >
                            {name ?? 'Not linked to Salesforce'}
                        </h3>
                        {hasExtraInfo && (
                            <>
                                <div
                                    className={classNames(
                                        'flex-initial whitespace-nowrap text-sm font-light',
                                        isUnlinked
                                            ? 'text-lime-800'
                                            : 'text-gray-700',
                                    )}
                                >
                                    {campaignType && (
                                        <span>{campaignType} campaign</span>
                                    )}
                                    {digAdType && campaignType && (
                                        <span>, </span>
                                    )}
                                    {digAdType && (
                                        <span>{digAdType} dig ad</span>
                                    )}
                                </div>
                                {promo && (
                                    <div
                                        className={classNames(
                                            'flex-initial whitespace-nowrap rounded-full px-3 py-1 text-xs',
                                            isUnlinked
                                                ? 'bg-lime-50 text-lime-700'
                                                : 'bg-white text-gray-700',
                                        )}
                                    >
                                        {promo}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {note !== undefined ? (
                        <div className="-mt-1">
                            <span
                                className={classNames(
                                    'whitespace-pre-wrap text-sm',
                                    isUnlinked
                                        ? 'text-lime-700'
                                        : 'text-gray-700',
                                )}
                            >
                                {note}
                            </span>
                        </div>
                    ) : null}
                </div>
                <div className="flex-none">
                    {budget !== undefined ? (
                        <div
                            className={`flex items-start justify-end text-xl font-semibold ${
                                isUnlinked ? 'text-lime-700' : 'text-gray-700'
                            }`}
                        >
                            {dollarFormat(budget)}
                        </div>
                    ) : null}
                    {packagePrice !== undefined ? (
                        <div
                            className={classNames(
                                '-mt-1 flex items-baseline justify-end',
                                isUnlinked ? 'text-lime-700' : 'text-gray-700',
                            )}
                        >
                            <span className="">
                                {dollarFormat(packagePrice)}
                            </span>
                            <span className="ml-1 align-baseline text-xs font-light">
                                pkg
                            </span>
                        </div>
                    ) : null}
                    {budget !== undefined && packagePrice !== undefined ? (
                        <div
                            className={classNames(
                                '-mt-1 flex items-baseline justify-end',
                                isUnlinked ? 'text-lime-700' : 'text-gray-700',
                            )}
                        >
                            <span className="">
                                {packagePrice <= 0
                                    ? '—'
                                    : numberFormat(budget / packagePrice, 5)}
                            </span>
                            <span className="ml-1 align-baseline text-xs font-light">
                                ratio
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
            {budgetNotMet && !isUnlinked && (
                <div className="m-2 mb-0 rounded-md border border-orange-300 bg-orange-200 px-2 py-1 text-sm leading-snug text-orange-900">
                    {overBudget
                        ? `Total budgeted spend (${dollarFormat(
                              totalWithoutAdjustments,
                          )}) exceeds the Salesforce budget by ${dollarFormat(
                              budgetDifference,
                          )}.`
                        : `Total budgeted spend (${dollarFormat(
                              totalWithoutAdjustments,
                          )}) is less than the Salesforce budget by ${dollarFormat(
                              budgetDifference,
                          )}.`}
                </div>
            )}
            <div className="px-2 py-2">
                <FieldArray
                    name={`${source}.${id}.lineItems`}
                    initialValue={lineItems.sort(sortByLabel)}
                >
                    {({ fields }) => (
                        <>
                            <ul className="">
                                {fields.map((name, index) => (
                                    <li key={name} className="relative mb-1">
                                        <Condition
                                            when={`${name}.suggested`}
                                            is={true}
                                            childrenIfNot={
                                                <LineItem
                                                    {...{
                                                        name,
                                                        values,
                                                        source,
                                                        id,
                                                        fields,
                                                        index,
                                                        sourceCampaignsLowered,
                                                        campaignCountForElseLabel,
                                                        assetInfo,
                                                        budgetViewFilter,
                                                    }}
                                                />
                                            }
                                        >
                                            <SuggestedLineItem
                                                {...{
                                                    name,
                                                    values,
                                                    source,
                                                    id,
                                                    fields,
                                                    index,
                                                }}
                                            />
                                        </Condition>
                                    </li>
                                ))}
                            </ul>
                            <div className="flex">
                                <div className="w-1/3">
                                    <button
                                        type="button"
                                        className="rounded-md bg-gray-100 py-1 px-2 text-xs text-gray-900 hover:bg-gray-500 hover:text-white focus:bg-gray-500 focus:text-white focus:outline-none"
                                        onClick={() =>
                                            fields.push({
                                                label: '',
                                                budget: 0,
                                                adsOnWeekends: true,
                                            })
                                        }
                                    >
                                        + Add line item
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </FieldArray>
            </div>
        </div>
    )
}

const formTotal = (values) => {
    const sources = Object.values(values)
    const total = sources.reduce((total, source) => {
        const assets = Object.values(source)
        return (
            total +
            assets.reduce((total, asset) => {
                return (
                    total +
                    (asset.lineItems?.reduce((total, lineItem) => {
                        const amount = lineItem.budget
                        return lineItem.suggested !== true &&
                            amount !== undefined &&
                            !isNaN(amount)
                            ? total + parseFloat(amount)
                            : total
                    }, 0) ?? 0)
                )
            }, 0)
        )
    }, 0)
    return total
}

const salesforceTotal = (budget) => {
    const total = budget.reduce((total, source) => {
        return (
            total +
            source.assets?.reduce((total, asset) => {
                return total + asset.budget
            }, 0)
        )
    }, 0)
    return total
}

const hasOneAssignment = (isElseItem) => (campaign) =>
    campaign.labelCount === 1 || (campaign.labelCount === 0 && isElseItem)

const campaignItemSort = (isElseItem) => (campaignA, campaignB) => {
    const goodAssignment = hasOneAssignment(isElseItem)
    // Sort campaigns with exactly 1 label first (uniquely assigned)
    // If both are that way, order by name.
    if (goodAssignment(campaignA) && !goodAssignment(campaignB)) {
        return -1
    }
    if (!goodAssignment(campaignA) && goodAssignment(campaignB)) {
        return 1
    }
    if (goodAssignment(campaignA) && goodAssignment(campaignB)) {
        return campaignA.name.localeCompare(campaignB.name)
    }

    // Sort duplicate assignments next, if both are dupes then order by name.
    if (campaignA.labelCount > 1 && campaignB.labelCount < 1) {
        return -1
    }
    if (campaignA.labelCount < 1 && campaignB.labelCount > 1) {
        return 1
    }
    if (campaignA.labelCount > 1 && campaignB.labelCount > 1) {
        return campaignA.name.localeCompare(campaignB.name)
    }

    // Sort unassigned campaigns last and order by name
    // (both are unassigned - labelCount === 0 - if we get here.)
    return campaignA.name.localeCompare(campaignB.name)
}

const sourceCampaignsData = (clientCampaigns, sourceLabelsLowered) =>
    clientCampaigns.map((campaign) => {
        const labelCount = sourceLabelsLowered.reduce(
            (count, label) =>
                count +
                Number(
                    label !== '' && campaign.name.toLowerCase().includes(label),
                ),
            0,
        )

        const labelArray = sourceLabelsLowered.filter((term) =>
            campaign.name.toLowerCase().includes(term),
        )

        return {
            name: campaign.name,
            spend: campaign.spend,
            status: campaign.status,
            labelArray,
            labelCount,
        }
    })

const sourceCampaignCoverageStatus = (sourceCampaignsData, isElseItem) =>
    sourceCampaignsData.some((c) => c.labelCount > 1)
        ? 'duplicate'
        : isElseItem || sourceCampaignsData.every((c) => c.labelCount === 1)
        ? 'covered'
        : 'not-covered'

const CampaignCoverage = ({
    clientId,
    clientCampaignsStatus,
    clientCampaigns,
    isElseItem,
    source,
    sourceLabelsLowered,
}) => {
    const sourceCampaignsDisplayData = sourceCampaignsData(
        clientCampaigns,
        sourceLabelsLowered,
    ).sort(campaignItemSort(isElseItem))
    const coverageStatus = sourceCampaignCoverageStatus(
        sourceCampaignsDisplayData,
        isElseItem,
    )
    const headingIcon =
        coverageStatus === 'duplicate' ? (
            <DuplicateUIIcon size="medium" />
        ) : coverageStatus === 'covered' ? (
            <CheckUIIcon size="medium" />
        ) : (
            <XUIIcon size="medium" />
        )

    const uniquelyCoveredCampaigns = sourceCampaignsDisplayData.reduce(
        (count, c) =>
            count +
            Number(c.labelCount === 1 || (isElseItem && c.labelCount === 0)),
        0,
    )
    const totalCampaigns = sourceCampaignsDisplayData.length

    const { isLoading, mutate: refresh } = useMutation(
        ({ clientId, source }: { clientId: string; source: Source }) =>
            PearApi.importSpends(clientId, source),
        {
            onSuccess: () => {
                invalidateClientBudgets(clientId)
            },
        },
    )

    return (
        <div className="h-full rounded-md bg-white shadow-md">
            <div className="flex items-center justify-between rounded-t-md bg-gray-50 px-3 py-1.5 font-medium tracking-wide">
                <div className="flex items-center justify-start gap-2">
                    <span className="leading-[15px]">
                        Campaign Coverage - {uniquelyCoveredCampaigns}/
                        {totalCampaigns}
                    </span>
                    <span className="flex items-center">{headingIcon}</span>
                </div>
                <span className="flex items-center">
                    <ReloadButton
                        isReloading={isLoading}
                        onClick={() => refresh({ clientId, source })}
                    />
                </span>
            </div>
            <div className="px-1 py-2">
                {clientCampaignsStatus === 'loading' ? (
                    <div>
                        <LoadingIcon />
                    </div>
                ) : clientCampaignsStatus === 'error' ? (
                    <div className="text-center text-sm">
                        <ErrorIcon />
                        Something went wrong.
                    </div>
                ) : (
                    sourceCampaignsDisplayData.map((campaignItem) => {
                        const matchIcon =
                            campaignItem.labelCount > 1 ? (
                                <DuplicateUIIcon />
                            ) : campaignItem.labelCount === 1 || isElseItem ? (
                                <CheckUIIcon />
                            ) : (
                                <XUIIcon />
                            )

                        let statusIcon = null
                        switch (campaignItem.status) {
                            case 'PAUSED':
                                statusIcon = <PauseUIIcon />
                                break
                            case 'ENABLED':
                                statusIcon = <PlayUIIcon />
                                break
                            default:
                        }
                        const labelArrayTags = campaignItem.labelArray.map(
                            (label) => {
                                return (
                                    <span className="flex items-center rounded bg-lime-100 px-1 text-center">
                                        {label}
                                    </span>
                                )
                            },
                        )
                        const campaignSource =
                            clientCampaigns && clientCampaigns.length
                                ? clientCampaigns[0].source.toLowerCase()
                                : ''

                        return (
                            <div
                                key={campaignItem.name}
                                data-type="campaignItem"
                                data-source={campaignSource}
                                data-label-count={campaignItem.labelCount}
                                className="flex gap-2 rounded-md py-1 px-2 text-xs transition hover:bg-gray-100"
                            >
                                <div className="flex items-center">
                                    {matchIcon}
                                </div>
                                <div
                                    className="flex items-center"
                                    title={campaignItem.status}
                                >
                                    {statusIcon}
                                </div>
                                <div
                                    className="whitespace-pre-wrap break-all"
                                    title={campaignItem.name}
                                >
                                    {campaignItem.name}
                                </div>
                                <div
                                    className="flex gap-1"
                                    title="Matching Line Items"
                                >
                                    {labelArrayTags}
                                </div>
                                <div
                                    className="ml-auto flex items-center font-medium text-cyan-600"
                                    title="MTD Cost"
                                >
                                    {dollarFormat(campaignItem.spend, 2)}
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

const sourceLabelsInfo = (source, values) => {
    const sourceLineItems = Object.values(
        (values[source] as object) ?? {},
    ).reduce(
        (allLineItems, { lineItems }) => allLineItems.concat(lineItems),
        [],
    )
    const sourceLabels = sourceLineItems.reduce(
        (allLabels, { label }) => allLabels.concat(label ?? ''),
        [],
    )
    const sourceLabelsLowered = sourceLabels.map((l) => l.toLowerCase())
    const isElseItem = sourceLabels.some((l) => l === 'Else')
    return { sourceLabelsLowered, isElseItem }
}

const validateSources =
    (isAdmin, sortedBudget, clientCampaigns) => (values: FormValues) => {
        const formErrors = {}
        for (const source of Object.keys(values ?? {})) {
            formErrors[source] = labelErrorsInSource(values?.[source] ?? {})
        }

        // To prevent non-admins from saving with non-aligned budgets, check if all
        // budgets match Salesforce.
        const budgetsMatchSalesforce = sortedBudget.reduce(
            (allGood, { source, assets }) =>
                allGood &&
                assets.reduce(
                    (allGoodInSource, { id: assetId, budget, lineItems }) => {
                        const { budgetDifference } = budgetComparison(
                            values,
                            source,
                            assetId,
                            budget,
                        )
                        return allGoodInSource && budgetDifference === 0
                    },
                    true,
                ),
            true,
        )

        // Don’t allow Non-Admins to Save Budgets with unaccepted Suggested Line Items, unless they are Promo Line items
        const promoLines = sortedBudget.some((item) =>
            (item.assets || []).some((asset) => asset.promo),
        )

        formErrors['budgetMatch'] =
            budgetsMatchSalesforce || promoLines || isAdmin
                ? undefined
                : "Only admins may save budgets that don't sum to the Salesforce budget."

        // To prevent non-admins from saving with non-covered campaigns, check if all
        // applicable campaigns are covered by a line item.
        const allCampaignsCovered = sortedBudget.reduce(
            (allGood, { source, assets }) => {
                const { sourceLabelsLowered, isElseItem } = sourceLabelsInfo(
                    source,
                    values,
                )
                const applicableClientCampaigns = clientCampaigns.filter(
                    (c) =>
                        c.source === source &&
                        (c.status === 'ENABLED' || c.spend > 0),
                )
                const campaignData = sourceCampaignsData(
                    applicableClientCampaigns,
                    sourceLabelsLowered,
                )
                const coverageStatus = sourceCampaignCoverageStatus(
                    campaignData,
                    isElseItem,
                )

                return allGood && coverageStatus === 'covered'
            },
            true,
        )

        formErrors['campaignCoverage'] =
            allCampaignsCovered || isAdmin
                ? undefined
                : "Only admins may save budgets that don't cover all campaigns."

        return formErrors
    }

const ClientBudgetEditing = () => {
    const [saveMessage, setSaveMessage] = useState({
        type: 'idle',
        message: '',
    })
    const { clientId }: { [key: string]: string } = useParams()

    const loginInfo = useLoginInfo()
    const {
        user: { admin: isAdmin },
    } = loginInfo

    const { data: budgetList = {} as BudgetList, status } =
        useClientBudgets(clientId)

    const { data: clientCampaigns, status: clientCampaignsStatus } =
        useClientCampaigns(clientId)

    const save = useMutation(
        (newBudgets: object) => PearApi.saveBudget(clientId, newBudgets),
        {
            onSuccess: () => {
                setSaveMessage({ type: 'success', message: 'Budgets saved.' })
            },
            onError: () => {
                setSaveMessage({
                    type: 'error',
                    message: 'There was a problem saving the budgets.',
                })
            },
        },
    )

    const sortedBudget = budgetList.budgets?.slice().sort(sortBySource) ?? []

    const [budgetViewFilter, setBudgetViewFilter] = useState('Ad Spend')
    const budgetViewModes = ['Ad Spend', 'Package']

    const onSubmit = (newBudgets) => {
        const finalBudgets = sortedBudget.map((sourceBudget) => ({
            ...sourceBudget,
            assets: sourceBudget.assets.map((asset) => ({
                id: asset.id === NO_LINKED_ASSET ? null : asset.id,
                lineItems: newBudgets[sourceBudget.source][asset.id].lineItems
                    .map(convertLineItemFormat)
                    .filter((lineItem) => lineItem.suggested !== true),
            })),
        }))
        save.mutate(finalBudgets)
    }

    const renderErrorBudgetTemplate = (
        message,
        button?,
        buttonText?,
        clientId?,
    ) => {
        return (
            <div className="w-1/3 rounded-md bg-white px-8 py-6 shadow-md">
                <p
                    className="text-center text-gray-800"
                    dangerouslySetInnerHTML={{ __html: message }}
                ></p>
                {button && (
                    <div className="flex justify-center pt-4">
                        <LinkButton to={`/clients?id=${clientId}`}>
                            {buttonText}
                        </LinkButton>
                    </div>
                )}
            </div>
        )
    }

    const renderErrorBudgetScreen = (budgetList) => {
        switch (budgetList.error) {
            case 'CLIENT_NOT_FOUND':
                return renderErrorBudgetTemplate('Client not found.')
            case 'NO_AD_ACCOUNTS':
                return renderErrorBudgetTemplate(
                    'No Ad accounts found for Client.<br />Please add one before editing budgets.',
                    true,
                    'Edit Client',
                    clientId,
                )
            case 'SALESFORCE_API_ERROR':
                return renderErrorBudgetTemplate(
                    'Error retrieving data from Salesforce.<br />Please try again later.',
                )
            default:
                return renderErrorBudgetTemplate(
                    'There was an error.<br />Please try again later.',
                )
        }
    }

    return (
        <SectionCloud className="h-full">
            <div className="flex h-full flex-col px-8 pb-6 pt-4">
                <div className="mb-2 flex items-center gap-x-4">
                    <h1 className="min-w-0 flex-auto text-2xl font-extrabold tracking-tight text-gray-700">
                        {budgetList.name}
                    </h1>
                    <GenericSelect
                        className="mr-2"
                        onChange={setBudgetViewFilter}
                        options={budgetViewModes}
                        label="Showing"
                        initialSelection={budgetViewFilter}
                    />
                    <div className="flex items-center justify-between gap-2">
                        <span className="rounded text-sm">View in</span>
                        <Link
                            className="rounded text-sm text-cyan-700 underline focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-1"
                            to={`${AppRoutes.BUDGETING}?search=${budgetList.name}`}
                        >
                            Budgeting
                        </Link>
                        {budgetList.opportunityUrl && (
                            <>
                                <span>|</span>
                                <a
                                    href={budgetList.opportunityUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="rounded text-sm text-cyan-700 underline focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-1"
                                >
                                    Salesforce
                                </a>
                            </>
                        )}
                    </div>
                </div>
                <div className="min-h-0 flex-auto gap-8">
                    <FormWindow
                        onSubmit={onSubmit}
                        mutators={{
                            ...arrayMutators,
                        }}
                        isSaving={save.isLoading}
                        saveMessage={saveMessage}
                        validate={validateSources(
                            isAdmin,
                            sortedBudget,
                            clientCampaigns,
                        )}
                        canSave={isAdmin}
                        bottomLabel={({ values }) => {
                            const formDollarAmount = dollarFormat(
                                formTotal(values),
                            )
                            const salesforceDollarAmount = dollarFormat(
                                salesforceTotal(sortedBudget),
                            )

                            return (
                                <>
                                    <span className="text-2xl text-gray-700">
                                        {formDollarAmount}
                                    </span>
                                    <span className="mx-2 text-lg text-gray-500">
                                        of
                                    </span>
                                    <span className="text-2xl text-gray-700">
                                        {salesforceDollarAmount}
                                    </span>
                                </>
                            )
                        }}
                    >
                        {({ values }) => {
                            return (
                                <Well>
                                    {(status === 'success' &&
                                        !budgetList.budgets?.length) ||
                                    budgetList.error ? (
                                        <div className="flex h-full items-center justify-center">
                                            {renderErrorBudgetScreen(
                                                budgetList,
                                            )}
                                        </div>
                                    ) : (
                                        <StatusDisplay status={status}>
                                            <div className="pb-2">
                                                {sortedBudget.map(
                                                    (
                                                        {
                                                            source,
                                                            accountId,
                                                            assets,
                                                        },
                                                        index,
                                                    ) => {
                                                        const {
                                                            sourceLabelsLowered,
                                                            isElseItem,
                                                        } = sourceLabelsInfo(
                                                            source,
                                                            values,
                                                        )

                                                        // Show campaigns that are enabled or have spend.
                                                        const applicableClientCampaigns =
                                                            clientCampaigns.filter(
                                                                (c) =>
                                                                    c.source ===
                                                                        source &&
                                                                    (c.status ===
                                                                        'ENABLED' ||
                                                                        c.spend >
                                                                            0),
                                                            )

                                                        const campaignCountForElseLabel =
                                                            applicableClientCampaigns.reduce(
                                                                (
                                                                    count,
                                                                    campaign,
                                                                ) =>
                                                                    sourceLabelsLowered.some(
                                                                        (
                                                                            label,
                                                                        ) =>
                                                                            campaign.name
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    label,
                                                                                ),
                                                                    )
                                                                        ? count
                                                                        : count +
                                                                          1,
                                                                0,
                                                            )
                                                        const platformLink =
                                                            PlatformLinks?.[
                                                                source
                                                            ]?.replace(
                                                                ':accountId',
                                                                accountId,
                                                            )

                                                        return (
                                                            <div className="">
                                                                <div
                                                                    className={classNames(
                                                                        'mb-1.5 flex-none',
                                                                        index !==
                                                                            0
                                                                            ? 'mt-8'
                                                                            : '',
                                                                    )}
                                                                >
                                                                    {platformLink ? (
                                                                        <h2 className="sticky top-0 block text-xl font-medium">
                                                                            <a
                                                                                className="rounded text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-1"
                                                                                href={
                                                                                    platformLink
                                                                                }
                                                                            >
                                                                                {
                                                                                    source
                                                                                }
                                                                            </a>
                                                                            <a
                                                                                className="ml-2 rounded text-lg text-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-1"
                                                                                href={
                                                                                    platformLink
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                aria-label="In New Tab"
                                                                            >
                                                                                <Icon type="ExternalLink" />
                                                                            </a>
                                                                        </h2>
                                                                    ) : (
                                                                        <h2 className="sticky top-0 block">
                                                                            {
                                                                                source
                                                                            }
                                                                        </h2>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className="flex gap-x-4"
                                                                    key={source}
                                                                >
                                                                    <div className="basis-[35%]">
                                                                        <CampaignCoverage
                                                                            {...{
                                                                                clientId,
                                                                                clientCampaignsStatus,
                                                                                clientCampaigns:
                                                                                    applicableClientCampaigns,
                                                                                isElseItem,
                                                                                source,
                                                                                sourceLabelsLowered,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="basis-[65%]">
                                                                        {assets.map(
                                                                            (
                                                                                asset,
                                                                                index,
                                                                            ) => (
                                                                                <Asset
                                                                                    {...{
                                                                                        assetInfo:
                                                                                            asset,
                                                                                        index,
                                                                                        values,
                                                                                        source,
                                                                                        clientCampaigns:
                                                                                            applicableClientCampaigns,
                                                                                        campaignCountForElseLabel,
                                                                                        budgetViewFilter,
                                                                                    }}
                                                                                    key={
                                                                                        asset.id
                                                                                    }
                                                                                />
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    },
                                                )}
                                            </div>
                                        </StatusDisplay>
                                    )}
                                </Well>
                            )
                        }}
                    </FormWindow>
                </div>
            </div>
        </SectionCloud>
    )
}

export default ClientBudgetEditing
