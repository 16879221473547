import { useCallback, memo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useTable } from 'react-table'
import { SectionCloud } from '../components/Layout'
import StatusDisplay from '../components/StatusDisplay'
import { classNames } from '../utility/Components'
import {
    useClient,
    useClientFeedValues,
    invalidateClientFeedValues,
} from '../hooks/apiHooks'
import { dollarFormat, numberFormat } from '../utility/Formatting'
import PearApi from '../apis/pearApi'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import NumberInput from '../components/elements/NumberInput'

const columns = [
    {
        Header: 'Key',
        accessor: 'key',
    },
    {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) =>
            value !== undefined ? dollarFormat(value, 0) : '',
    },
    {
        Header: 'Discount',
        accessor: 'discount',
        Cell: ({ value }) =>
            value !== undefined ? dollarFormat(value, 0) : '',
    },
    {
        Header: 'Stock',
        accessor: 'stock',
        Cell: ({ value }) => (value !== undefined ? numberFormat(value) : ''),
    },
    {
        Header: 'Lease',
        accessor: 'lease',
        Cell: ({ value }) =>
            value !== undefined ? dollarFormat(value, 0) : '',
    },
    {
        Header: 'Headline',
        accessor: 'headline',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
]

const columnWidths = {
    key: 'flex-1 min-w-0',
    price: 'w-24',
    discount: 'w-24',
    lease: 'w-20',
    stock: 'w-20',
    headline: 'w-24',
    description: 'w-24',
}

const Square = ({ className = '' }) => (
    <span
        className={classNames(
            'inline-block h-3 w-3 border border-cyan-600 bg-cyan-400',
            className,
        )}
    >
        <span className="sr-only">Fourbot value</span>{' '}
    </span>
)

const Circle = ({ className = '' }) => (
    <span
        className={classNames(
            'inline-block h-3 w-3 rounded-full border border-green-600 bg-green-300',
            className,
        )}
    >
        <span className="sr-only">Manual entry</span>{' '}
    </span>
)

const Triangle = ({ className = '' }) => (
    <span className={classNames('inline-block', className)}>
        <span className="sr-only">No values</span>
        <span
            className="border-b-solid block border-b-[0.75rem] border-b-orange-500"
            style={{
                width: 0,
                height: 0,
                borderLeft: '.375rem solid transparent',
                borderRight: '.375rem solid transparent',
            }}
        />
    </span>
)

const AutomaticPricing = () => {
    const { clientId }: { [key: string]: string } = useParams()

    const { data: client } = useClient(clientId)
    const { name: clientName } = client ?? { name: '' }

    const { data: feedValues = {}, status } = useClientFeedValues(clientId)
    const attributes = [
        'price',
        'discount',
        'stock',
        'lease',
        'headline',
        'description',
    ]
    const feedValuesInUse = Object.keys(feedValues).map((key) => {
        const { fourbotValues, customValues } = feedValues[key]

        const currentFeedInfo = { key }
        attributes.forEach((attribute) => {
            const sources = { fourbot: false, custom: false }
            let currentValue = undefined
            let currentEndDate = undefined
            if (fourbotValues?.[attribute] != null) {
                sources.fourbot = true
                currentValue = fourbotValues[attribute]
            }
            if (customValues?.[attribute] != null) {
                sources.custom = true
                currentValue = customValues[attribute].value
                currentEndDate = customValues[attribute].endDate ?? ''
            }
            currentFeedInfo[attribute] = currentValue
            currentFeedInfo[attribute + 'EndDate'] = currentEndDate
            currentFeedInfo[attribute + 'Sources'] = sources
        })
        return currentFeedInfo
    })

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data: feedValuesInUse,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
        })

    const [selectedKey, setSelectedKey] = useUrlQueryParameter(
        'key',
        feedValuesInUse[0]?.key ?? '',
    )
    const RenderRow = useCallback(
        (row) => {
            prepareRow(row)
            return (
                <Row
                    row={row}
                    showDetails={() => {}}
                    selectedKey={selectedKey}
                    setSelectedKey={setSelectedKey}
                    {...row.getRowProps()}
                />
            )
        },
        [prepareRow, selectedKey, setSelectedKey],
    )

    return (
        <div className="flex h-full gap-8">
            <SectionCloud className="flex flex-1 flex-col px-8 py-6">
                <div className="">
                    <h1 className="mb-2 text-3xl font-extrabold tracking-tight text-gray-600">
                        Feed Values for {clientName}
                    </h1>
                    <p
                        className="flex items-center text-sm text-gray-700"
                        aria-hidden="true"
                    >
                        Colored shapes represent existing values from Fourbot (
                        <Square />
                        ), from manual entries (<Circle />
                        ), or where neither is present (<Triangle />
                        ).
                    </p>
                </div>
                <div className="mt-2 min-h-0 flex-auto">
                    <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                            <div
                                className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg"
                                {...getTableProps()}
                            >
                                <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                    <div
                                        className="flex-none bg-gray-50 pl-2 pr-2"
                                        style={{ width: 'calc(100% - 0.9em)' }}
                                    >
                                        {headerGroups.map((headerGroup) => (
                                            <div
                                                className="flex gap-6"
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {headerGroup.headers.map(
                                                    (column) => {
                                                        const { id } =
                                                            column ?? {}
                                                        const justification =
                                                            numberAttributes.includes(
                                                                id,
                                                            )
                                                                ? 'text-right'
                                                                : ''
                                                        const spacing =
                                                            columnWidths[id]
                                                        const header =
                                                            column.render(
                                                                'Header',
                                                            )
                                                        return (
                                                            <div
                                                                className={classNames(
                                                                    'py-3 text-xs font-medium uppercase text-gray-700 focus:text-cyan-900 focus:outline-none',
                                                                    justification,
                                                                    spacing,
                                                                )}
                                                                {...column.getHeaderProps()}
                                                                title={
                                                                    undefined
                                                                }
                                                            >
                                                                <span className="">
                                                                    {header ===
                                                                    'Condition'
                                                                        ? 'Cond.'
                                                                        : header}
                                                                </span>
                                                            </div>
                                                        )
                                                    },
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="min-h-0 flex-auto bg-white">
                                        {rows.length > 0 ? (
                                            <div
                                                className="h-full overflow-y-scroll"
                                                {...getTableBodyProps()}
                                            >
                                                {rows.map(RenderRow)}
                                            </div>
                                        ) : status === 'loading' ||
                                          status === 'idle' ? (
                                            <div className="h-full">
                                                <StatusDisplay
                                                    status={status}
                                                />
                                            </div>
                                        ) : (
                                            <div className="block w-full">
                                                <div className="block w-full">
                                                    <div className="p-16 text-center text-lg">
                                                        No feed values found for
                                                        this client.
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionCloud>
            <SectionCloud className="w-1/3 px-8 py-6">
                <div className="flex h-full flex-col">
                    <h2 className="600 mb-5 flex-none truncate text-2xl font-bold">
                        Values for {selectedKey}
                    </h2>
                    <div className="min-h-0 flex-1 overflow-y-auto">
                        {attributes.map((attribute) => (
                            <FeedValueEditor
                                clientId={clientId}
                                key={attribute}
                                valuesKey={selectedKey}
                                attribute={attribute}
                                values={feedValues}
                            />
                        ))}
                    </div>
                </div>
            </SectionCloud>
        </div>
    )
}

const Row = memo(
    ({
        row,
        showDetails,
        selectedKey,
        setSelectedKey,
        ...otherProps
    }: {
        row: any
        showDetails: (campaigns: [], lineItemName) => void
        selectedKey: string
        setSelectedKey: (key: string) => void
    }) => {
        const { key } = row.original
        const isSelected = selectedKey === key
        return (
            <button
                className={classNames(
                    'flex w-full items-center gap-6 pl-2 pr-2',
                    isSelected ? 'bg-gray-100' : 'bg-white hover:bg-gray-50',
                )}
                {...otherProps}
                onClick={() => setSelectedKey(key)}
            >
                {row.cells.map((cell) => {
                    const { id } = cell?.column ?? {}
                    const justification = numberAttributes.includes(id)
                        ? ' justify-end'
                        : ''
                    const spacing = columnWidths[id]
                    const color =
                        isSelected && id === 'key'
                            ? 'text-gray-900 font-bold'
                            : isSelected
                            ? 'text-gray-700 font-medium'
                            : id === 'key'
                            ? 'text-gray-900 font-medium'
                            : 'text-gray-700'

                    const isFourbotValue =
                        row.original?.[id + 'Sources']?.fourbot
                    const isCustomValue = row.original?.[id + 'Sources']?.custom
                    const isValue = isFourbotValue || isCustomValue
                    const isKey = id === 'key'

                    const content = (
                        <div
                            className={classNames(
                                'flex-initial truncate text-sm',
                                color,
                            )}
                            title={
                                numberAttributes.includes(id) ? '' : cell.value
                            }
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell')}
                        </div>
                    )

                    return (
                        <div
                            key={id}
                            className={classNames(
                                'flex items-center gap-1.5 py-2',
                                spacing,
                                justification,
                            )}
                        >
                            {!isKey && isValue ? (
                                <>
                                    {content}
                                    {isFourbotValue && (
                                        <div>
                                            <Square />
                                        </div>
                                    )}
                                    {isCustomValue && (
                                        <div>
                                            <Circle />
                                        </div>
                                    )}
                                </>
                            ) : !isKey ? (
                                <div
                                    className={classNames(
                                        numberAttributes.includes(id)
                                            ? 'text-right'
                                            : '',
                                    )}
                                >
                                    <Triangle />
                                </div>
                            ) : (
                                content
                            )}
                        </div>
                    )
                })}
            </button>
        )
    },
)

const dollarAttributes = ['price', 'discount', 'lease']
const integerAttributes = ['stock']
const textAttributeMaxLengths = { headline: 30, description: 90 }
const numberAttributes = dollarAttributes.concat(integerAttributes)

interface CustomValue {
    value: string | number
    date: string
}

const FeedValueEditor = ({ clientId, valuesKey, attribute, values }) => {
    const valuesSet = values[valuesKey] ?? {}

    const { mutate: updateValue } = useMutation(
        (value: CustomValue) => {
            return PearApi.updateClientFeedValue(
                clientId,
                valuesKey,
                attribute,
                value.value,
                value.date,
            )
        },
        {
            onSettled: () => {
                invalidateClientFeedValues(clientId)
            },
        },
    )

    const [customValue, setCustomValue] = useState('' as number | string)
    const [endDate, setEndDate] = useState('')
    const [customValueLength, setCustomValueLength] = useState(0)

    const updateCustomValue = (value) => {
        setCustomValue(value)
        setCustomValueLength(value.length)
    }

    const rawFourbotValue = valuesSet.fourbotValues?.[attribute] ?? ''
    const fourbotValue =
        dollarAttributes.includes(attribute) && rawFourbotValue !== ''
            ? dollarFormat(rawFourbotValue, 0)
            : numberAttributes.includes(attribute) && rawFourbotValue !== ''
            ? numberFormat(rawFourbotValue)
            : rawFourbotValue
    const rawCustomValue = valuesSet.customValues?.[attribute]?.value ?? ''

    const previousCustomValue =
        dollarAttributes.includes(attribute) && rawCustomValue !== ''
            ? dollarFormat(rawCustomValue, 0)
            : numberAttributes.includes(attribute) && rawCustomValue !== ''
            ? numberFormat(rawCustomValue)
            : rawCustomValue

    const isNumberAttribute = numberAttributes.includes(attribute)
    const textAttributeMaxLength = textAttributeMaxLengths[attribute] ?? 20
    const isTextAttribute = textAttributeMaxLength > 20
    const isTextareaAttribute = textAttributeMaxLength > 30
    const now = new Date()
    const offset = now.getTimezoneOffset()
    const today = new Date(now.getTime() - offset * 60 * 1000)
    const todayYmd = today.toISOString().split('T')[0]

    return (
        <div className="mt-3 flex gap-4">
            <div>
                <h3 className="w-16 capitalize text-gray-700">{attribute}</h3>
                {['lease', 'description', 'headline'].includes(attribute) && (
                    <input
                        className="mt-1 w-full rounded-md border border-gray-300 px-3 py-1 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                        type="date"
                        onChange={(event) => setEndDate(event.target.value)}
                        min={todayYmd}
                    />
                )}
            </div>
            <div className="col-start-2 min-w-0 flex-1">
                <div className="flex pl-4 text-gray-900">
                    {!isTextAttribute && (
                        <div
                            className="flex w-40 items-center"
                            title={!isNumberAttribute ? fourbotValue : ''}
                        >
                            <div>
                                <Square className="mr-1" />
                            </div>
                            <span className="truncate">{fourbotValue}</span>
                        </div>
                    )}
                    <div
                        className="flex min-w-0 flex-1 items-center"
                        title={!isNumberAttribute ? previousCustomValue : ''}
                    >
                        <div>
                            <Circle className="mr-1" />
                        </div>
                        <span className="truncate">{previousCustomValue}</span>
                    </div>
                    {isTextAttribute && (
                        <div className="min-w-0 flex-1 pr-2 text-right">
                            <span className="text-xs">
                                {customValueLength}/{textAttributeMaxLength}
                            </span>
                        </div>
                    )}
                </div>
                <div className="mt-1 pl-4">
                    {!isNumberAttribute && !isTextareaAttribute && (
                        <div className="pr-1">
                            <input
                                type="text"
                                value={customValue}
                                maxLength={textAttributeMaxLength}
                                onChange={(event) =>
                                    updateCustomValue(event.target.value)
                                }
                                className="w-full rounded-md border border-gray-300 px-3 py-1 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                            />
                        </div>
                    )}
                    {isTextareaAttribute && (
                        <div className="pr-1">
                            <textarea
                                value={customValue}
                                maxLength={textAttributeMaxLength}
                                onChange={(event) =>
                                    updateCustomValue(event.target.value)
                                }
                                className="h-20 w-full resize-none rounded-md border border-gray-300 px-3 py-1 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                            ></textarea>
                        </div>
                    )}
                    <div className="mt-1 flex w-full items-center">
                        {isNumberAttribute ? (
                            <NumberInput
                                value={customValue}
                                onChange={(event) =>
                                    updateCustomValue(
                                        parseFloat(event.target.value),
                                    )
                                }
                                className="w-full rounded-md border border-gray-300 px-3 py-1 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                            />
                        ) : (
                            <span className="w-36"></span>
                        )}
                        <button
                            className={classNames(
                                'ml-4 inline rounded-md bg-cyan-50 px-3 py-1 text-sm text-cyan-600 hover:bg-cyan-100 hover:text-cyan-900 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1',
                            )}
                            onClick={() =>
                                updateValue({
                                    value: customValue,
                                    date: endDate,
                                })
                            }
                        >
                            Update
                        </button>
                        <button
                            className="ml-2 inline rounded-md px-3  py-1 text-sm font-light text-gray-700 hover:font-normal hover:text-gray-800 focus:outline-none focus:ring focus:ring-gray-600 focus:ring-offset-1"
                            onClick={() =>
                                updateValue({
                                    value: isNumberAttribute ? 0 : '',
                                    date: '',
                                })
                            }
                        >
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutomaticPricing
